import { createIcon } from "@illa-design/react"

export const TransformerIcon = createIcon({
  title: "TransformerIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75V3h3v-.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75V3a3 3 0 0 1 3 3v13.5a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3v-.75z"
        fill="#FFCD00"
      />
      <path
        d="M10.471 13.645c0 .423-.036.832-.108 1.229-.072.396-.22.751-.445 1.066-.216.315-.536.567-.959.756-.414.189-.967.284-1.66.284-.513 0-.95-.077-1.31-.23a2.535 2.535 0 0 1-.89-.648 2.641 2.641 0 0 1-.514-.986 4.457 4.457 0 0 1-.162-1.228v-.513h1.688v.5c0 .549.09.976.27 1.282.189.297.54.445 1.053.445.27 0 .49-.04.661-.121a.947.947 0 0 0 .419-.351c.108-.162.18-.36.216-.594.036-.234.054-.5.054-.796V7.11h1.687v6.534zm3.16-.108c0 .369.063.684.19.945.126.261.301.477.526.648.225.162.482.284.77.364.296.072.616.108.958.108.369 0 .684-.04.945-.121.261-.09.473-.203.634-.338.163-.135.28-.288.352-.459.072-.18.107-.36.107-.54 0-.369-.085-.639-.256-.81a1.346 1.346 0 0 0-.54-.377 10.394 10.394 0 0 0-1.201-.352c-.45-.117-1.008-.265-1.675-.445a3.848 3.848 0 0 1-1.039-.418 2.601 2.601 0 0 1-.648-.595 2.17 2.17 0 0 1-.351-.688 2.947 2.947 0 0 1-.095-.742c0-.487.1-.905.297-1.256.207-.36.477-.657.81-.891a3.427 3.427 0 0 1 1.135-.513 4.79 4.79 0 0 1 1.282-.176c.504 0 .976.068 1.418.203.45.126.841.32 1.174.58.342.262.612.585.81.973.198.377.297.819.297 1.322h-1.688c-.044-.62-.256-1.057-.634-1.309-.378-.261-.86-.392-1.444-.392-.199 0-.401.023-.608.068a1.704 1.704 0 0 0-.567.202 1.24 1.24 0 0 0-.432.378 1.072 1.072 0 0 0-.162.608c0 .342.104.612.31.81.217.189.496.333.838.432.036.009.175.05.418.121a63.135 63.135 0 0 1 1.728.46c.297.072.509.126.634.162.316.098.59.233.824.404.234.171.427.37.58.595.163.216.28.45.352.702.08.252.121.504.121.756 0 .54-.112 1.003-.337 1.39a2.763 2.763 0 0 1-.864.931 3.68 3.68 0 0 1-1.229.527 5.662 5.662 0 0 1-1.404.176 5.778 5.778 0 0 1-1.553-.203 3.634 3.634 0 0 1-1.268-.621 3.055 3.055 0 0 1-.865-1.066c-.216-.441-.328-.959-.337-1.553h1.688z"
        fill="#fff"
      />
    </>
  ),
})

TransformerIcon.displayName = "TransformerIcon"
