import { createIcon } from "@illa-design/react"

export const CouchDBIcon = createIcon({
  title: "CouchDBIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <g clipPath="url(#clip0_9898_13652)">
        <path d="M24 0H0V24H24V0Z" fill="white" />
        <path
          d="M19.5 15C19.5 15.9946 18.976 16.482 18 16.4993L5.99998 16.5C5.02398 16.4827 4.49997 15.9946 4.49997 15C4.49997 14.0055 5.02398 13.5181 5.99998 13.5008L18 13.5C18.976 13.5172 19.5 14.0055 19.5 15ZM18 17.2507L5.99998 17.25C5.02398 17.2673 4.49997 17.7555 4.49997 18.75C4.49997 19.7446 5.02398 20.232 5.99998 20.2493L18 20.2502C18.9759 20.2329 19.5 19.7446 19.5 18.75C19.5 17.7555 18.976 17.268 18 17.2507ZM21.75 9.00171C20.7741 9.01896 20.25 9.5055 20.25 10.5001V18.75C20.25 19.7446 20.774 20.232 21.75 20.2493V20.2474C23.214 20.1958 24 18.7338 24 15.75V12.0001C24.0002 10.0109 23.214 9.03618 21.75 9.00171ZM2.25003 9.00087C0.785975 9.03534 0 10.0109 0 12.0001V15.75C0 18.7338 0.785975 20.1958 2.25003 20.2476V20.2494C3.22604 20.2322 3.74995 19.7446 3.74995 18.7502V10.5001C3.74995 9.5055 3.22604 9.01812 2.25003 9.00087ZM21.75 8.25005C21.75 5.76357 20.4401 4.54516 18 4.50207V4.5H5.99998V4.50207C3.56001 4.54516 2.25003 5.76357 2.25003 8.25005C3.71398 8.27591 4.49997 9.00817 4.49997 10.5001C4.49997 11.9919 5.28602 12.723 6.74998 12.7488L17.25 12.7501C18.714 12.7242 19.5 11.9919 19.5 10.5001C19.5 9.00817 20.286 8.27591 21.75 8.25005Z"
          fill="#D82C20"
        />
      </g>
      <defs>
        <clipPath id="clip0_9898_13652">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
})
