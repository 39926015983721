import { createIcon } from "@illa-design/react"

export const MariaDbIcon = createIcon({
  title: "MariaDbIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M23.857 4.158a.347.347 0 0 0-.233-.08c-.232 0-.53.16-.69.239a2.179 2.179 0 0 1-.927.254c-.308.01-.574.028-.917.064-2.048.213-2.956 1.776-3.834 3.29-.478.824-.971 1.675-1.648 2.335a4.39 4.39 0 0 1-.446.378c-.7.522-1.58.891-2.267 1.154-.658.251-1.377.479-2.073.697-.637.2-1.236.39-1.789.595-.25.092-.462.16-.648.226-.503.167-.867.288-1.396.65a9.788 9.788 0 0 0-.558.41c-.42.337-.792.729-1.105 1.166a6.907 6.907 0 0 1-.925 1.117c-.111.109-.31.16-.607.16-.349 0-.771-.073-1.219-.148-.46-.08-.936-.16-1.345-.16-.332 0-.586.054-.776.164 0 0-.32.187-.454.428l.132.06c.205.11.396.246.566.404.178.164.375.305.588.419.068.027.13.068.181.12-.056.08-.138.187-.223.3-.472.617-.747 1.007-.59 1.219.074.039.157.058.24.056 1.028 0 1.58-.268 2.28-.605.202-.099.41-.2.65-.303.408-.178.848-.46 1.314-.76.617-.398 1.255-.806 1.873-1.004.51-.155 1.04-.23 1.572-.221.656 0 1.342.088 2.005.173.495.063 1.007.128 1.51.16.195.011.376.017.551.017.235 0 .47-.012.704-.038l.056-.019c.352-.217.517-.681.678-1.13.103-.29.189-.55.323-.718a.215.215 0 0 1 .027-.022.032.032 0 0 1 .04.007v.012c-.085 1.759-.79 2.874-1.506 3.866l-.478.513s.669 0 1.05-.147c1.388-.415 2.437-1.33 3.2-2.79.189-.375.357-.76.504-1.152.013-.033.134-.093.122.076 0 .05-.007.105-.01.16 0 .034-.006.069-.007.104-.02.239-.08.763-.08.763l.429-.23c1.037-.655 1.834-1.972 2.437-4.024.252-.855.436-1.704.598-2.453.195-.895.363-1.668.559-1.967.301-.47.761-.787 1.206-1.095l.182-.126c.558-.393 1.116-.847 1.239-1.692v-.02c.095-.617.02-.778-.066-.852z"
      fill="#003545"
    />
  ),
})

MariaDbIcon.displayName = "MariaDbIcon"
