// @generated by protobuf-ts 2.8.3
// @generated from protobuf file "ILLA_PROTO.proto" (package "tutorial", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime"
import type { IBinaryWriter } from "@protobuf-ts/runtime"
import { WireType } from "@protobuf-ts/runtime"
import type { BinaryReadOptions } from "@protobuf-ts/runtime"
import type { IBinaryReader } from "@protobuf-ts/runtime"
import { UnknownFieldHandler } from "@protobuf-ts/runtime"
import type { PartialMessage } from "@protobuf-ts/runtime"
import { reflectionMergePartial } from "@protobuf-ts/runtime"
import { MESSAGE_TYPE } from "@protobuf-ts/runtime"
import { MessageType } from "@protobuf-ts/runtime"

/**
 * @generated from protobuf message tutorial.MovingMessageBin
 */
export interface MovingMessageBin {
  /**
   * @generated from protobuf field: tutorial.Signal signal = 1;
   */
  signal: Signal // see package ws const define
  /**
   * @generated from protobuf field: tutorial.Target target = 2;
   */
  target: Target
  /**
   * @generated from protobuf field: string clientID = 3;
   */
  clientID: string // message sender client ID
  /**
   * @generated from protobuf field: bool needBroadcast = 4;
   */
  needBroadcast: boolean
  /**
   * @generated from protobuf field: string userID = 5;
   */
  userID: string // message sender user ID
  /**
   * @generated from protobuf field: string nickname = 6;
   */
  nickname: string // message sender nickname
  /**
   * @generated from protobuf field: int32 status = 7;
   */
  status: number // set instance to status, 0 for empty status
  /**
   * @generated from protobuf field: string parentDisplayName = 8;
   */
  parentDisplayName: string // parent component displayname
  /**
   * @generated from protobuf field: string displayNames = 9;
   */
  displayNames: string // message affected component display names, separate with comma ","
  /**
   * @generated from protobuf field: uint32 cursorXInteger = 10;
   */
  cursorXInteger: number //  cursor's position with dot number
  /**
   * @generated from protobuf field: uint32 cursorYInteger = 11;
   */
  cursorYInteger: number //  cursor's position with dot number
  /**
   * @generated from protobuf field: float cursorXMod = 12;
   */
  cursorXMod: number //  cursor's position with dot number
  /**
   * @generated from protobuf field: float cursorYMod = 13;
   */
  cursorYMod: number //  cursor's position with dot number
  /**
   * @generated from protobuf field: uint32 widgetX = 14;
   */
  widgetX: number //  widget'position with dot number
  /**
   * @generated from protobuf field: uint32 widgetY = 15;
   */
  widgetY: number //  widget'position with dot number
  /**
   * @generated from protobuf field: uint32 widgetW = 16;
   */
  widgetW: number //  widget'shape with dot number
  /**
   * @generated from protobuf field: uint32 widgetH = 17;
   */
  widgetH: number //  widget'shape with dot number
}
/**
 * @generated from protobuf enum tutorial.Signal
 */
export enum Signal {
  /**
   * @generated from protobuf enum value: SIGNAL_PING = 0;
   */
  PING = 0,
  /**
   * @generated from protobuf enum value: SIGNAL_ENTER = 1;
   */
  ENTER = 1,
  /**
   * @generated from protobuf enum value: SIGNAL_LEAVE = 2;
   */
  LEAVE = 2,
  /**
   * @generated from protobuf enum value: SIGNAL_CREATE_STATE = 3;
   */
  CREATE_STATE = 3,
  /**
   * @generated from protobuf enum value: SIGNAL_DELETE_STATE = 4;
   */
  DELETE_STATE = 4,
  /**
   * @generated from protobuf enum value: SIGNAL_UPDATE_STATE = 5;
   */
  UPDATE_STATE = 5,
  /**
   * @generated from protobuf enum value: SIGNAL_MOVE_STATE = 6;
   */
  MOVE_STATE = 6,
  /**
   * @generated from protobuf enum value: SIGNAL_CREATE_OR_UPDATE_STATE = 7;
   */
  CREATE_OR_UPDATE_STATE = 7,
  /**
   * @generated from protobuf enum value: SIGNAL_BROADCAST_ONLY = 8;
   */
  BROADCAST_ONLY = 8,
  /**
   * @generated from protobuf enum value: SIGNAL_PUT_STATE = 9;
   */
  PUT_STATE = 9,
  /**
   * @generated from protobuf enum value: SIGNAL_GLOBAL_BROADCAST_ONLY = 10;
   */
  GLOBAL_BROADCAST_ONLY = 10,
  /**
   * @generated from protobuf enum value: SIGNAL_COOPERATE_ATTACH = 11;
   */
  COOPERATE_ATTACH = 11,
  /**
   * @generated from protobuf enum value: SIGNAL_COOPERATE_DISATTACH = 12;
   */
  COOPERATE_DISATTACH = 12,
  /**
   * @generated from protobuf enum value: SIGNAL_MOVE_CURSOR = 13;
   */
  MOVE_CURSOR = 13,
}
/**
 * @generated from protobuf enum tutorial.Target
 */
export enum Target {
  /**
   * placeholder for nothing
   *
   * @generated from protobuf enum value: TARGET_NOTHING = 0;
   */
  NOTHING = 0,
  /**
   * ComponentsState
   *
   * @generated from protobuf enum value: TARGET_COMPONENTS = 1;
   */
  COMPONENTS = 1,
  /**
   * DependenciesState
   *
   * @generated from protobuf enum value: TARGET_DEPENDENCIES = 2;
   */
  DEPENDENCIES = 2,
  /**
   * DragShadowState
   *
   * @generated from protobuf enum value: TARGET_DRAG_SHADOW = 3;
   */
  DRAG_SHADOW = 3,
  /**
   * DottedLineSquareState
   *
   * @generated from protobuf enum value: TARGET_DOTTED_LINE_SQUARE = 4;
   */
  DOTTED_LINE_SQUARE = 4,
  /**
   * DisplayNameState
   *
   * @generated from protobuf enum value: TARGET_DISPLAY_NAME = 5;
   */
  DISPLAY_NAME = 5,
  /**
   * only for broadcast
   *
   * @generated from protobuf enum value: TARGET_APPS = 6;
   */
  APPS = 6,
  /**
   * only for broadcast
   *
   * @generated from protobuf enum value: TARGET_RESOURCE = 7;
   */
  RESOURCE = 7,
  /**
   * only for broadcast
   *
   * @generated from protobuf enum value: TARGET_ACTION = 8;
   */
  ACTION = 8,
  /**
   * only for broadcast
   *
   * @generated from protobuf enum value: TARGET_CURSOR = 9;
   */
  CURSOR = 9,
}
// @generated message type with reflection information, may provide speed optimized methods
class MovingMessageBin$Type extends MessageType<MovingMessageBin> {
  constructor() {
    super("tutorial.MovingMessageBin", [
      {
        no: 1,
        name: "signal",
        kind: "enum",
        T: () => ["tutorial.Signal", Signal, "SIGNAL_"],
      },
      {
        no: 2,
        name: "target",
        kind: "enum",
        T: () => ["tutorial.Target", Target, "TARGET_"],
      },
      { no: 3, name: "clientID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: "needBroadcast",
        kind: "scalar",
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 5, name: "userID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
      {
        no: 8,
        name: "parentDisplayName",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: "displayNames",
        kind: "scalar",
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: "cursorXInteger",
        kind: "scalar",
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 11,
        name: "cursorYInteger",
        kind: "scalar",
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 12, name: "cursorXMod", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
      { no: 13, name: "cursorYMod", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
      { no: 14, name: "widgetX", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
      { no: 15, name: "widgetY", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
      { no: 16, name: "widgetW", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
      { no: 17, name: "widgetH", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
    ])
  }
  create(value?: PartialMessage<MovingMessageBin>): MovingMessageBin {
    const message = {
      signal: 0,
      target: 0,
      clientID: "",
      needBroadcast: false,
      userID: "",
      nickname: "",
      status: 0,
      parentDisplayName: "",
      displayNames: "",
      cursorXInteger: 0,
      cursorYInteger: 0,
      cursorXMod: 0,
      cursorYMod: 0,
      widgetX: 0,
      widgetY: 0,
      widgetW: 0,
      widgetH: 0,
    }
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    })
    if (value !== undefined)
      reflectionMergePartial<MovingMessageBin>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: MovingMessageBin,
  ): MovingMessageBin {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* tutorial.Signal signal */ 1:
          message.signal = reader.int32()
          break
        case /* tutorial.Target target */ 2:
          message.target = reader.int32()
          break
        case /* string clientID */ 3:
          message.clientID = reader.string()
          break
        case /* bool needBroadcast */ 4:
          message.needBroadcast = reader.bool()
          break
        case /* string userID */ 5:
          message.userID = reader.string()
          break
        case /* string nickname */ 6:
          message.nickname = reader.string()
          break
        case /* int32 status */ 7:
          message.status = reader.int32()
          break
        case /* string parentDisplayName */ 8:
          message.parentDisplayName = reader.string()
          break
        case /* string displayNames */ 9:
          message.displayNames = reader.string()
          break
        case /* uint32 cursorXInteger */ 10:
          message.cursorXInteger = reader.uint32()
          break
        case /* uint32 cursorYInteger */ 11:
          message.cursorYInteger = reader.uint32()
          break
        case /* float cursorXMod */ 12:
          message.cursorXMod = reader.float()
          break
        case /* float cursorYMod */ 13:
          message.cursorYMod = reader.float()
          break
        case /* uint32 widgetX */ 14:
          message.widgetX = reader.uint32()
          break
        case /* uint32 widgetY */ 15:
          message.widgetY = reader.uint32()
          break
        case /* uint32 widgetW */ 16:
          message.widgetW = reader.uint32()
          break
        case /* uint32 widgetH */ 17:
          message.widgetH = reader.uint32()
          break
        default:
          let u = options.readUnknownField
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            )
          let d = reader.skip(wireType)
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            )
      }
    }
    return message
  }
  internalBinaryWrite(
    message: MovingMessageBin,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* tutorial.Signal signal = 1; */
    if (message.signal !== 0)
      writer.tag(1, WireType.Varint).int32(message.signal)
    /* tutorial.Target target = 2; */
    if (message.target !== 0)
      writer.tag(2, WireType.Varint).int32(message.target)
    /* string clientID = 3; */
    if (message.clientID !== "")
      writer.tag(3, WireType.LengthDelimited).string(message.clientID)
    /* bool needBroadcast = 4; */
    if (message.needBroadcast !== false)
      writer.tag(4, WireType.Varint).bool(message.needBroadcast)
    /* string userID = 5; */
    if (message.userID !== "")
      writer.tag(5, WireType.LengthDelimited).string(message.userID)
    /* string nickname = 6; */
    if (message.nickname !== "")
      writer.tag(6, WireType.LengthDelimited).string(message.nickname)
    /* int32 status = 7; */
    if (message.status !== 0)
      writer.tag(7, WireType.Varint).int32(message.status)
    /* string parentDisplayName = 8; */
    if (message.parentDisplayName !== "")
      writer.tag(8, WireType.LengthDelimited).string(message.parentDisplayName)
    /* string displayNames = 9; */
    if (message.displayNames !== "")
      writer.tag(9, WireType.LengthDelimited).string(message.displayNames)
    /* uint32 cursorXInteger = 10; */
    if (message.cursorXInteger !== 0)
      writer.tag(10, WireType.Varint).uint32(message.cursorXInteger)
    /* uint32 cursorYInteger = 11; */
    if (message.cursorYInteger !== 0)
      writer.tag(11, WireType.Varint).uint32(message.cursorYInteger)
    /* float cursorXMod = 12; */
    if (message.cursorXMod !== 0)
      writer.tag(12, WireType.Bit32).float(message.cursorXMod)
    /* float cursorYMod = 13; */
    if (message.cursorYMod !== 0)
      writer.tag(13, WireType.Bit32).float(message.cursorYMod)
    /* uint32 widgetX = 14; */
    if (message.widgetX !== 0)
      writer.tag(14, WireType.Varint).uint32(message.widgetX)
    /* uint32 widgetY = 15; */
    if (message.widgetY !== 0)
      writer.tag(15, WireType.Varint).uint32(message.widgetY)
    /* uint32 widgetW = 16; */
    if (message.widgetW !== 0)
      writer.tag(16, WireType.Varint).uint32(message.widgetW)
    /* uint32 widgetH = 17; */
    if (message.widgetH !== 0)
      writer.tag(17, WireType.Varint).uint32(message.widgetH)
    let u = options.writeUnknownFields
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      )
    return writer
  }
}
/**
 * @generated MessageType for protobuf message tutorial.MovingMessageBin
 */
export const MovingMessageBin = new MovingMessageBin$Type()
