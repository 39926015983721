export const colorSchemes = [
  "white",
  "blackAlpha",
  "grayBlue",
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "cyan",
  "purple",
]
